/* html {
  font-size: 15px;
} */
@import url("https://fonts.googleapis.com/css2?family=Licorice&display=swap");

.happyNewYear {
  font-family: "Licorice", cursive;
}

.font-small {
  font-size: 0.8em;
}

#scroll-to-top {
  position: fixed;
  bottom: 2px;
  right: 2px;
}

.genPdf {
  height: 794px;
  width: 1123px;
  position: absolute;
  top: 0;
  left: -1123px;
  z-index: -1;
}

.text-green-light {
  color: #92d050;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-logo-home {
  height: 30vmin;
  pointer-events: none;
}
.table-perso {
  max-width: calc(100vw - 295px);
}

.annonce {
  width: 70%;
  font-size: 1.2em;
  overflow: hidden;
  -webkit-box-shadow: inset 0 0 0.3em 0.05em gray;
  box-shadow: inset 0 0 0.3em 0.05em gray;
}

.annonce > :first-child {
  display: inline-block; /* modèle de boîte en ligne */
  padding-right: 2em; /* un peu d'espace pour la transition */
  padding-left: 100%; /* placement à droite du conteneur */
  white-space: nowrap; /* pas de passage à la ligne */
  animation: defilement-rtl 15s infinite linear;
  -webkit-animation: defilement-rtl 15s infinite linear;
}

.new {
  position: relative;
}

.new::after {
  content: "< Nouveau";
  font-weight: bold;
  text-align: left;
  display: block;
  white-space: nowrap;
  color: red;
  position: absolute;
  left: 105%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@-webkit-keyframes defilement-rtl {
  0% {
    transform: translateX(0); /* position initiale à droite */
    -webkit-transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); /* position finale à gauche */
    -webkit-transform: translateX(-100%);
  }
}

@keyframes defilement-rtl {
  0% {
    transform: translateX(0); /* position initiale à droite */
    -webkit-transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); /* position finale à gauche */
    -webkit-transform: translateX(-100%);
  }
}

@media (max-width: 992px) {
  .table-perso {
    max-width: 100%;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .animFadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }
  .animSideIn {
    -webkit-animation-name: navSideIn;
    animation-name: navSideIn;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }
  .App-logo {
    -webkit-animation: App-logo-spin infinite 1s linear;
    animation: App-logo-spin infinite 1s linear;
  }
  .App-logo-home {
    -webkit-animation: App-logo-spin-home infinite 3s linear;
    animation: App-logo-spin-home infinite 3s linear;
  }
  .new::after {
    -webkit-animation: anime-new infinite 1.5s linear;
    animation: anime-new infinite 1.5s linear;
  }
  .new:hover::after {
    -webkit-animation: none;
    animation: none;
  }
}

@-webkit-keyframes anime-new {
  0% {
    -webkit-transform: scale(1) translateY(-50%);
    transform: scale(1) translateY(-50%);
  }
  25% {
    -webkit-transform: scale(0.9) translateY(-50%);
    transform: scale(0.9) translateY(-50%);
  }
  75% {
    -webkit-transform: scale(1.1) translateY(-50%);
    transform: scale(1.1) translateY(-50%);
  }
  100% {
    -webkit-transform: scale(1) translateY(-50%);
    transform: scale(1) translateY(-50%);
  }
}

@keyframes anime-new {
  0% {
    -webkit-transform: scale(1) translateY(-50%);
    transform: scale(1) translateY(-50%);
  }
  25% {
    -webkit-transform: scale(0.9) translateY(-50%);
    transform: scale(0.9) translateY(-50%);
  }
  75% {
    -webkit-transform: scale(1.1) translateY(-50%);
    transform: scale(1.1) translateY(-50%);
  }
  100% {
    -webkit-transform: scale(1) translateY(-50%);
    transform: scale(1) translateY(-50%);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes navSideIn {
  from {
    -webkit-transform: translateY(+100%);
    transform: translateY(+100%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes navSideIn {
  from {
    -webkit-transform: translateY(+100%);
    transform: translateY(+100%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes App-logo-spin {
  /* from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } */
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}

@keyframes App-logo-spin {
  /* from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } */
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}

@-webkit-keyframes App-logo-spin-home {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}

@keyframes App-logo-spin-home {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}

/* .App {
  text-align: center;
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */
